module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/bokuami-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"94c02ea49d6b2463c27f89a786a972fd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Merriweather:400,300,300italic,400italic,700,700italic","Merriweather+Sans:400,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150982418-1","head":false,"anonymize":false,"respectDNT":true,"exclude":["/preview/**"],"pageTransitionDelay":0,"sampleRate":100,"siteSpeedSampleRate":10},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
